// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import { Card } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import { getAllAnnouncements } from "api_services/OtherServices";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import secureLocalStorage from "react-secure-storage";
import { searchAllBanners } from "api_services/BannerService";
import { getRelevantBanners } from "api_services/BannerService";
import { getPotentialPartners } from "api_services/MemberService";
import BannerCarousel from "layouts/dashboard/components/Banner/bannerCarousel";
import MDAvatar from "components/MDAvatar";

import revenue from "assets/images/revenue.png";
import leads from "assets/images/attract-leads.png";
import mind from "assets/images/mind.png";
import streamline from "assets/images/streamline.png";
import policy from "assets/images/policy.png";
import cargoInsurance from "assets/images/insure.png";
import dashboard from "assets/images/dashboard.png";
import checkmark from "assets/images/checkmark.png";
import freytInsure from "assets/images/freyt-insure-logo.png";
import tigermar from "assets/images/tigermar-logo.png";

export function addFavouriteMarker(memberList, userFavourites, idFieldName) {
  let isFavourite = false;
  for (let index = 0; index < memberList.length; index++) {
    const member = memberList[index];
    isFavourite = userFavourites.includes(member[idFieldName]);
    memberList[index] = {
      ...member,
      isFavourite: isFavourite,
    };
  }
}

function FreytInsure() {
  const editor = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.EDIT_ACCESS
  ];
  const userCompanies = JSON.parse(secureLocalStorage.getItem("linked-user-companies"));
  const userFavourites = JSON.parse(secureLocalStorage.getItem("user-favourites"));

  const [AllMemberData, setAllMemberData] = useState([]);
  const [AllFavouriteMemberData, setAllFavouriteMemberData] = useState([]);
  const userCompanyIds = JSON.parse(secureLocalStorage.getItem("current-user-data"))["companies"];
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    userCompanyIds.length > 0 ? userCompanyIds[0]["id"] : ""
  );
  const [banners, setBanners] = useState([]);
  const [partnerLoading, setPartnerLoading] = useState(false);
  const [marketOpportunities, setMarketOpportunities] = useState({
    data: [],
    nextPage: false,
    page: 0,
  });
  // const [importingFrom, setImportingFrom] = useState({
  //   data: [],
  //   nextPage: false,
  //   page: 0,
  // });
  // const [exportingTo, setExportingTo] = useState({
  //   data: [],
  //   nextPage: false,
  //   page: 0,
  // });
  const navigate = useNavigate();
  // const [memberAddress, setMemberAddress] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [deleteType, setDeleteType] = useState();
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const [isAnnLoading, setAnnIsLoading] = useState(true);
  const [isFavouriteLoading, setFavouriteIsLoading] = useState(true);
  const userToken = secureLocalStorage.getItem("custom-token");
  const [annID, setAnnID] = useState("");
  const [bannerID, setBannerID] = useState("");

  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [positionState, setPositionState] = useState({
    openTwo: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openTwo } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, openTwo: false });
  };

  const handleOpen = (id, type) => {
    setDeleteType(type);
    if (type === 0) {
      setAnnID(id);
    } else {
      setBannerID(id);
    }
    setOpen(true);
  };

  const advantages = [
    {
      icon: revenue,
      heading: "Compliant Revenue Stream",
      description:
        "Create a new income stream and stand out from competitors by earning revenue from each transport.",
    },
    {
      icon: leads,
      heading: "Move More Leads",
      description:
        "Move more leads without restrictions on commodity type or category, with an extensive list and higher limits.",
    },
    {
      icon: mind,
      heading: "Peace of Mind",
      description: "Have total coverage regardless of freight carrier.",
    },
    {
      icon: streamline,
      heading: "Streamlined Claims",
      description:
        "Easy claim filing with prompt settlement, directly with the insurance company—no middleman or extra steps.",
    },
    {
      icon: policy,
      heading: "Policy Flexibility",
      description:
        "Flexible policy options let you use your existing coverage or bring your own, tailored to your needs.",
    },
  ];

  const ctas = [
    {
      icon: cargoInsurance,
      heading: "Searching for Cargo Insurance can be time consuming, here is how we can help.",
      btnText: "Start a Quote",
      link: "https://www.freytworld.com/",
      bullets: [
        "Cargo Insurance in seconds.",
        "Providing a 24/7, quote and bind single shipment offering to the entire Freyt Consol Network.",
        "Multiple cover types able to combat the problem of underinsurance.",
      ],
    },
    {
      icon: dashboard,
      heading: "Your Insurance, Simplified and Centralized",
      btnText: "View Dashboard",
      link: "https://www.freytworld.com/",
      bullets: [
        "Effortlessly manage all your policies—past, present, and saved—in one place.",
        "Simplify claims by filing directly from the dashboard, with no extra steps.",
        "Gain a complete view of your company's insurance landscape with an intuitive dashboard.",
      ],
    },
  ];

  async function getBannerList() {
    let response;
    if (editor) {
      response = await searchAllBanners(userToken, 0, "", 5);
    } else {
      let cities = [];
      let countries = [];
      userCompanies.forEach((company) => {
        cities.push(company["city"]);
        countries.push(company["country"]);
      });
      response = await getRelevantBanners(userToken, 0, cities, countries, "FreytInsure", 100);
    }
    if (response !== null) {
      setIsBannerLoading(false);
      setBanners(response.searchResults);
    } else {
      setIsBannerLoading(true);
      //console.log(response);
    }
  }

  async function getPotentialPartnersList() {
    if (!editor) {
      setPartnerLoading(true);
      const response = await getPotentialPartners(userToken, 0, selectedCompanyId, 6, "");
      if (response !== null) {
        const members = response.searchResults;
        addFavouriteMarker(members, userFavourites, "memberId");
        setMarketOpportunities({
          data: members,
          nextPage: response.morePages,
          page: 0,
        });

        setPartnerLoading(false);
      } else {
        //console.log(response);
      }
    }
  }

  function getSearchedMemberList() {
    navigate(`/directory/search/${searchData}`);
  }

  useEffect(() => {
    setIsLoading(true);
    getBannerList();
    getTheAnnouncements();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getPotentialPartnersList();
  }, [selectedCompanyId]);

  const [announcements, setAnnouncements] = useState([]);

  async function getTheAnnouncements() {
    const response = await getAllAnnouncements(3);

    if (response !== null) {
      setAnnIsLoading(false);
      setAnnouncements(response);
    } else {
      setAnnIsLoading(true);
      //console.log(response);
    }
  }

  //////////////////////////////////////

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const Advantages = advantages.map((adv) => (
    <Grid item xs={12} md={2.4} xl={2.4} mb={2}>
      <Card sx={{ mx: 1, p: 2, height: "100%" }} key={adv.title}>
        <MDBox display="flex" alignItems="center" mb={1}>
          <MDAvatar
            src={adv.icon}
            alt="advatange icon"
            size="sm"
            variant="square"
            sx={{ borderRadius: 0 }}
          />
          <MDTypography variant="button" fontWeight="medium" color="black" mt={0} ml={1}>
            {adv.heading}
          </MDTypography>
        </MDBox>
        <MDTypography variant="caption" color="black" fontWeight="none" mt={0.5}>
          {adv.description}
        </MDTypography>
      </Card>
    </Grid>
  ));

  const CallToActions = ctas.map((cta) => (
    <Grid item xs={12} md={6} xl={6} mb={2} mt={2}>
      <Card sx={{ mx: 1, px: { xs: 3.5, lg: 2, xl: 2 }, py: 3, height: "100%" }} key={cta.title}>
        <MDBox
          display="flex"
          alignItems="center"
          mb={1}
          sx={{ flexDirection: { xs: "column", lg: "row", xl: "row" } }}
        >
          <MDBox
            component="img"
            src={cta.icon}
            alt="CTA icon"
            width="150px"
            sx={{ marginBottom: { xs: "30px", lg: "0px", xl: "0px" } }}
          />
          <MDBox sx={{ marginLeft: { xs: "0px", lg: "40px", xl: "40px" } }}>
            <MDTypography variant="h5" fontWeight="medium" color="black" mb={2}>
              {cta.heading}
            </MDTypography>
            {cta.bullets.map((text) => (
              <MDBox display="flex" alignItems="center" mb={1}>
                <MDAvatar
                  src={checkmark}
                  alt="cta icon"
                  size="xs"
                  variant="square"
                  sx={{ borderRadius: 0 }}
                />
                <MDTypography variant="h6" color="black" fontWeight="regular" mt={0.5} ml={1}>
                  {text}
                </MDTypography>
              </MDBox>
            ))}
            <MDBox display="flex" sx={{ justifyContent: { xs: "center", lg: "left", xl: "left" } }}>
              <MDBox
                borderRadius="lg"
                py={1}
                px={4}
                mt={2}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#044B99",
                  width: "fit-content",
                  textAlign: "center",
                }}
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  fontWeight="regular"
                  component={Link}
                  to={cta.link}
                  target="_blank"
                >
                  {cta.btnText}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <MDBox
          sx={{
            display: { xs: "none", sm: "none", md: "grid" },
          }}
        >
          <Grid container display="grid" spacing={2}>
            {isBannerLoading ? (
              <Stack spacing={2} m={3}>
                <Skeleton variant="rectangular" width={"100%"} height={50} />
                <Skeleton variant="rectangular" width={"100%"} height={20} />
                <Skeleton variant="rectangular" width={"100%"} height={10} />
              </Stack>
            ) : (
              <Grid item>
                <BannerCarousel banners={banners} />
              </Grid>
            )}
          </Grid>
        </MDBox>
        <Grid container mt={2}>
          {Advantages}
        </Grid>
        <Grid container>{CallToActions}</Grid>
        <MDBox mt={3} display="flex" justifyContent="center" alignItems="center">
          <MDBox
            mr={2}
            component="img"
            src={freytInsure}
            alt="FreytInsure logo"
            width="99.19px"
            height="62px"
          />
          <MDBox component="img" src={tigermar} alt="Tigermar logo" width="115px" />
        </MDBox>
      </div>
      <Footer />
    </DashboardLayout>
  );
}

export default FreytInsure;
