import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CircularProgress,
  Dialog,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  createTheme,
  FormControlLabel,
  Checkbox,
  Switch,
  styled,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useTranslation } from "react-i18next";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { addMemberSchema } from "layouts/validationSchema/addMemberSchema";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import { GENERAL_CONSTS } from "utils/constants/general.constants";
import { addNewMember } from "api_services/MemberService";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import { uploadDocument } from "api_services/UserService";
import secureLocalStorage from "react-secure-storage";
import { addBranchToMember } from "api_services/MemberService";
import { searchOptions } from "api_services/OptionService";
import { ThemeProvider } from "@emotion/react";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#044B99",
      darker: "#044B99",
    },
  },
});

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  "& .MuiSwitch-switchBase": {
    padding: 4,
    paddingTop: 6,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
    },
  },
}));

function getCurrentDate() {
  let date = new Date();
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0");
  let yyyy = String(date.getFullYear());
  return yyyy + "-" + mm + "-" + dd;
  // return a date in the form yyyy-mm-dd
}

function AddBranch() {
  const userToken = secureLocalStorage.getItem("custom-token");
  const editor = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.EDIT_ACCESS
  ];
  const { id } = useParams();
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [allCountriesSelected, setAllCountriesSelected] = useState(false);
  const [commaSeparatedCountryList, setCommaSeparatedCountryList] = useState(false);

  async function createNewMember(memberData) {
    setLoadingOpen(true);
    setSaving(true);

    const response = await addNewMember(memberData, userToken);
    if (response.id !== null) {
      console.log("Member created Successfully");
      return response.id;
    } else {
      setLoadingOpen(false);
      setSaving(false);
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log("member creation failed");
    }
  }

  async function addBranchToHeadOffice(headOfficeId, branchId) {
    setLoadingOpen(true);
    setSaving(true);
    const response = await addBranchToMember(headOfficeId, branchId, userToken);

    if (response == "ok") {
      //console.log("branch created Successfully");
      navigate(-1);
    } else {
      setLoadingOpen(false);
      setSaving(false);
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log("member creation failed");
    }
  }

  ////////////////////////////////////////////

  const [date, setDate] = useState(getCurrentDate());
  const [dateTwo, setDateTwo] = useState(getCurrentDate());
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [country, setCountry] = useState("");

  const [imageUrl, setImageUrl] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [profileText, setProfileText] = useState("");
  const [profileValidateMsg, setProfileValidateMsg] = useState("");

  // const [serviceList, setServiceList] = useState([]);
  // const [airlineList, setAirlineList] = useState([]);
  // const [carrierList, setCarrierList] = useState([]);

  const handleEditorChange = (content) => {
    setProfileText(content);
    // const errorMessage = validateEditorContent(content);
    // setProfileValidateMsg(errorMessage);
  };

  const handleCommaSeparateToggleChange = (e) => {
    setCommaSeparatedCountryList(e.target.checked);
    if (e.target.checked) {
      setFieldValue("marketsToDevelopCommaSeparated", values.marketsToDevelop.join(","));
    }
  };

  const handleCheckChange = (event) => {
    const { value } = event.target;

    if (values.conferences.includes(value)) {
      setFieldValue(
        "conferences",
        values.conferences.filter((option) => option !== value)
      );
      return;
    }

    setFieldValue("conferences", [...values.conferences, value]);
  };

  const validateEditorContent = (content) => {
    if (!content || content.trim() === "") {
      setProfileValidateMsg("Company profile is required !");
    }
  };

  const [positionState, setPositionState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, open: false });
  };

  async function uploadProfilePicture(image) {
    if (image) {
      const response = await uploadDocument(
        image,
        GENERAL_CONSTS.MEMBER_PROFILE_IMAGES_ROOT_FOLDER,
        userToken,
        null,
        null,
        null
      );
      if (response) {
        //console.log("Image uploaded Successfully", response);
        const downloadUri = response.fileDownloadUri;
        return downloadUri.substring(0, downloadUri.indexOf("?"));
      } else {
        //console.log("Image upload failed");
      }
    } else {
      return null;
    }
  }

  const onSubmit = async (e) => {
    try {
      validateEditorContent(profileText);
      if (!(profileText == "")) {
        //console.log(values);
        //console.log("profile Text : ", profileText);
        const profileImgDownloadUri = await uploadProfilePicture(avatar);
        // const serviceIds = values.services.map((s) => s.id);
        // const carrierIds = values.carriers.map((s) => s.id);
        // const airlineIds = values.airlines.map((s) => s.id);
        const memberObj = {
          tenantId: "FC",
          companyName: values.companyName,
          headOffice: "",
          websiteUrl: values.websiteUrl,
          linkedInUrl: values.linkedInUrl,
          officeContactNumber: values.officeContactNumber,
          file: null,
          companyProfile: profileText,
          companyLogo: profileImgDownloadUri,
          email: values.email,
          approvedTimestamp: Date.parse(values.approvedTimestamp),
          expiryDate: Date.parse(values.expiryDate),
          address: [
            {
              city: values.city,
              // block: values.block,
              // street: values.street,
              unitNo: values.unitNo,
              country: values.country,
              region: GENERAL_CONSTS.REGIONS[values.country]["region"],
              subregion: GENERAL_CONSTS.REGIONS[values.country]["subregion"],
              // floorNo: values.floorNo,
              postcode: values.postcode,
            },
          ],
          location: {
            latitude: values.lat,
            longitude: values.long,
          },

          // totalImport: values.totalImport,
          // totalExport: values.totalExport,
          // services: serviceIds,
          // carriers: carrierIds,
          // airlines: airlineIds,
          // exportCountries: values.exportCountries,
          // importCountries: values.importCountries,
          marketsToDevelop: allCountriesSelected
            ? ["All countries"]
            : commaSeparatedCountryList
              ? values.marketsToDevelopCommaSeparated.length > 0
                ? values.marketsToDevelopCommaSeparated.split(",").map((s) => s.trim())
                : []
              : values.marketsToDevelop,
          conferences: values.conferences,
          memberType: values.memberType,
          memberFee: values.memberFee,
        };

        const branchId = await createNewMember(memberObj);
        // console.log("Branch saved: ", branchId);
        if (branchId !== null) {
          await addBranchToHeadOffice(id, branchId);
          // console.log("Adding to head office saved: ", branchId);
        } else {
          setLoadingOpen(false);
          setSaving(false);
          setPositionState({ open: true, vertical: "top", horizontal: "center" });
          //console.log("member creation failed");
        }
      }
    } catch (e) {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log(e.message);
    }
  };

  const {
    getRootProps: getRootLogoProps,
    getInputProps: getInputLogoProps,
    acceptedFiles,
  } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: (acceptedFile) => {
      setImageUrl(URL.createObjectURL(acceptedFile[0]));
      setFieldValue("file", acceptedFile[0]);
      setAvatar(acceptedFile[0]);
    },
  });

  const fileAcceptedItems = acceptedFiles.map(() => (
    <MDBox
      mt={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ width: { xs: "50%", sm: "30%", md: "15%" } }}
    >
      <img src={imageUrl} alt={imageUrl} width="100%" />
    </MDBox>
  ));

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    initialValues: {
      companyName: "",
      unitNo: "",
      // floorNo: "",
      // block: "",
      postcode: "",
      country: "",
      // street: "",
      city: "",
      lat: "",
      long: "",
      websiteUrl: "",
      linkedInUrl: "",
      email: "",
      officeContactNumber: "",
      approvedTimestamp: date,
      expiryDate: date,
      file: null,
      companyProfile: "",
      // totalImport: 0,
      // totalExport: 0,
      // services: [],
      // carriers: [],
      // airlines: [],
      // exportCountries: [],
      // importCountries: [],
      marketsToDevelop: [],
      marketsToDevelopCommaSeparated: "",
      conferences: [],
      memberType: "",
      memberFee: 0,
    },

    validationSchema: addMemberSchema,
    onSubmit,
  });

  // async function getOptionLists() {
  //   const airlineList = await searchOptions(userToken, 0, "airline", "");
  //   const carrierList = await searchOptions(userToken, 0, "carrier", "");
  //   const serviceList = await searchOptions(userToken, 0, "service", "");

  //   if (airlineList !== null) {
  //     setAirlineList(airlineList);
  //   }
  //   if (carrierList !== null) {
  //     setCarrierList(carrierList);
  //   }
  //   if (serviceList !== null) {
  //     setServiceList(serviceList);
  //   }
  // }

  // useEffect(() => {
  //   getOptionLists();
  // }, []);

  return (
    <>
      <style>
        {` .ql-snow .ql-stroke , .ql-snow .ql-picker {
            stroke: #7b809a;
            color:#7b809a;
            font-family: 'poppins;
          }
          .ql-snow .ql-picker.ql-expanded .ql-picker-options {
            z-index: 9;
          }
          .ql-editor {
            color: black !important;
          }
          `}
      </style>
      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ mb: 5 }}>
          <MDBox pt={3} pb={2} pl={3.5} pr={3}>
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium" color="black" mb={2}>
                New Branch
              </MDTypography>
            </MDBox>
            <MDBox component="form" role="form" onSubmit={handleSubmit}>
              <MDTypography variant="h6" fontWeight="medium" color="black" mb={4}>
                Profile information
              </MDTypography>
              <Grid container spacing={1}>
                <Grid xs={12} md={12} xl={12}>
                  <MDBox px={0.5}>
                    <MDInput
                      type="text"
                      fullWidth
                      error={errors.companyName && touched.companyName && true}
                      focused={
                        (errors.companyName && touched.companyName && true) ||
                        (values.companyName && true)
                      }
                      label={
                        errors.companyName && touched.companyName
                          ? errors.companyName
                          : "Company Name"
                      }
                      id="companyName"
                      onChange={handleChange}
                      value={values.companyName}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                {/* <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="number"
                      error={errors.totalImport && touched.totalImport && true}
                      focused={
                        (errors.totalImport && touched.totalImport && true) ||
                        (values.totalImport && true)
                      }
                      label={
                        errors.totalImport && touched.totalImport
                          ? errors.totalImport
                          : "Total Import %"
                      }
                      id="totalImport"
                      onChange={handleChange}
                      value={values.totalImport}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="number"
                      error={errors.totalExport && touched.totalExport && true}
                      focused={
                        (errors.totalExport && touched.totalExport && true) ||
                        (values.totalExport && true)
                      }
                      label={
                        errors.totalExport && touched.totalExport
                          ? errors.totalExport
                          : "Total Export %"
                      }
                      id="totalExport"
                      onChange={handleChange}
                      value={values.totalExport}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid> */}

                <Grid xs={12} md={6} xl={6} mt={2}>
                  <MDBox display="flex" alignItems="center" px={0.5}>
                    <FormControl
                      container
                      fullWidth
                      error={errors.memberType && touched.memberType && true}
                      focused={
                        (errors.memberType && touched.memberType && true) ||
                        (values.memberType && true)
                      }
                    >
                      <InputLabel id="demo-simple-select-label">
                        {errors.memberType && touched.memberType
                          ? errors.memberType
                          : "Member Type"}
                      </InputLabel>
                      <Select
                        error={errors.memberType && touched.memberType && true}
                        focused={
                          (errors.memberType && touched.memberType && true) ||
                          (values.memberType && true)
                        }
                        label={
                          errors.memberType && touched.memberType
                            ? errors.memberType
                            : "Member Type"
                        }
                        id="memberType"
                        fullWidth
                        {...getFieldProps("memberType")}
                        value={[values.memberType]}
                        labelId="demo-simple-select-label"
                        onChange={handleChange}
                        sx={{ height: 36 }}
                      >
                        {GENERAL_CONSTS.MEMBER_TYPES.map((type, idx) => (
                          <MenuItem key={idx} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6} mt={2} px={0.5}>
                  <MDInput
                    type="number"
                    error={errors.memberFee && touched.memberFee && true}
                    focused={
                      (errors.memberFee && touched.memberFee && true) || (values.memberFee && true)
                    }
                    label={errors.memberFee && touched.memberFee ? errors.memberFee : "Member Fee"}
                    id="memberFee"
                    onChange={handleChange}
                    value={values.memberFee}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </Grid>

                {/* <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={2} px={0.5}>
                    <FormControl
                      fullWidth
                      error={errors.services && touched.services && true}
                      focused={errors.services && touched.services && true}
                    >
                      <InputLabel id="demo-multiple-chip-label">
                        {errors.services && touched.services ? errors.services : "Services"}
                      </InputLabel>
                      <Select
                        error={errors.services && touched.services && true}
                        label={errors.services && touched.services ? errors.services : "Services"}
                        id="label"
                        labelId="demo-multiple-name-label"
                        multiple
                        fullWidth
                        {...getFieldProps("services")}
                        value={values.services}
                        renderValue={(selected) => (
                          <MDBox
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignContent: "center",
                              overflowX: "auto",
                              minHeight: 42.4,
                              gap: 1,
                              color: "white",
                            }}
                            py={1}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value.id}
                                label={
                                  <MDTypography variant="button" color="black">
                                    {value.name}
                                  </MDTypography>
                                }
                              />
                            ))}
                          </MDBox>
                        )}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        sx={{ minHeight: 42.4, borderColor: "red" }}
                      >
                        {serviceList.map((item, idx) => (
                          <MenuItem
                            sx={{ my: 1, borderRadius: 50, width: "min-content", color: "info" }}
                            key={idx}
                            value={item}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid> */}

                <Grid xs={12} md={12} xl={12} mt={2}>
                  {editor ? (
                    <MDBox px={0.7}>
                      <FormControlLabel
                        control={
                          <MaterialUISwitch
                            size="small"
                            checked={commaSeparatedCountryList}
                            onChange={(e) => handleCommaSeparateToggleChange(e)}
                          />
                        }
                        label={
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="black"
                            width="max-content"
                          >
                            <span style={{ color: "black", fontSize: "10.3px" }}>
                              Add as comma separated list
                            </span>
                          </MDTypography>
                        }
                      />
                    </MDBox>
                  ) : null}
                  <MDBox mt={2} px={0.5}>
                    {commaSeparatedCountryList ? (
                      <MDInput
                        type="text"
                        fullWidth
                        error={
                          errors.marketsToDevelopCommaSeparated &&
                          touched.marketsToDevelopCommaSeparated &&
                          true
                        }
                        focused={
                          (errors.marketsToDevelopCommaSeparated &&
                            touched.marketsToDevelopCommaSeparated &&
                            true) ||
                          (values.marketsToDevelopCommaSeparated && true)
                        }
                        label={
                          errors.marketsToDevelopCommaSeparated &&
                          touched.marketsToDevelopCommaSeparated
                            ? errors.marketsToDevelopCommaSeparated
                            : "Markets Looking To Develop In"
                        }
                        id="marketsToDevelopCommaSeparated"
                        onChange={handleChange}
                        value={values.marketsToDevelopCommaSeparated}
                        onBlur={handleBlur}
                      />
                    ) : (
                      <FormControl
                        fullWidth
                        error={errors.marketsToDevelop && touched.marketsToDevelop && true}
                        focused={errors.marketsToDevelop && touched.marketsToDevelop && true}
                      >
                        <InputLabel id="demo-multiple-chip-label">
                          {errors.marketsToDevelop && touched.marketsToDevelop
                            ? errors.marketsToDevelop
                            : "Markets Looking To Develop In"}
                        </InputLabel>
                        <Select
                          disabled={allCountriesSelected}
                          error={errors.marketsToDevelop && touched.marketsToDevelop && true}
                          label={
                            errors.marketsToDevelop && touched.marketsToDevelop
                              ? errors.marketsToDevelop
                              : "Markets Looking To Develop In"
                          }
                          id="label"
                          labelId="demo-multiple-name-label"
                          multiple
                          fullWidth
                          {...getFieldProps("marketsToDevelop")}
                          value={values.marketsToDevelop}
                          renderValue={
                            allCountriesSelected
                              ? null
                              : (selected) => (
                                  <MDBox
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignContent: "center",
                                      overflowX: "auto",
                                      minHeight: 42.4,
                                      gap: 1,
                                      color: "white",
                                    }}
                                    py={1}
                                  >
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={
                                          <MDTypography variant="button" color="black">
                                            {value}
                                          </MDTypography>
                                        }
                                      />
                                    ))}
                                  </MDBox>
                                )
                          }
                          onChange={handleChange}
                          MenuProps={MenuProps}
                          sx={{ minHeight: 42.4, borderColor: "red" }}
                        >
                          {GENERAL_CONSTS.COUNTRIES.map((item, idx) => (
                            <MenuItem
                              sx={{ my: 1, borderRadius: 50, width: "min-content", color: "info" }}
                              key={idx}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {editor ? (
                      <ThemeProvider theme={theme}>
                        <Grid container>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="allCountriesSelected"
                                checked={allCountriesSelected}
                                onChange={(e) => {
                                  setAllCountriesSelected(e.target.checked);
                                  setFieldValue("marketsToDevelop", []);
                                  setFieldValue("marketsToDevelopCommaSeparated", "");
                                }}
                              />
                            }
                            label={
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="black"
                                width="max-content"
                              >
                                <span style={{ color: "black", fontSize: "11.2px" }}>
                                  All countries
                                </span>
                              </MDTypography>
                            }
                          />
                        </Grid>
                      </ThemeProvider>
                    ) : null}
                  </MDBox>
                </Grid>

                {/* <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={2} px={0.5}>
                    <FormControl
                      fullWidth
                      error={errors.exportCountries && touched.exportCountries && true}
                      focused={errors.exportCountries && touched.exportCountries && true}
                    >
                      <InputLabel id="demo-multiple-chip-label">
                        {errors.exportCountries && touched.exportCountries
                          ? errors.exportCountries
                          : "Top Countries Exporting To"}
                      </InputLabel>
                      <Select
                        error={errors.exportCountries && touched.exportCountries && true}
                        label={
                          errors.exportCountries && touched.exportCountries
                            ? errors.exportCountries
                            : "Top Countries Exporting To"
                        }
                        id="label"
                        labelId="demo-multiple-name-label"
                        multiple
                        fullWidth
                        {...getFieldProps("exportCountries")}
                        value={values.exportCountries}
                        renderValue={(selected) => (
                          <MDBox
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignContent: "center",
                              overflowX: "auto",
                              minHeight: 42.4,
                              gap: 1,
                              color: "white",
                            }}
                            py={1}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  <MDTypography variant="button" color="black">
                                    {value}
                                  </MDTypography>
                                }
                              />
                            ))}
                          </MDBox>
                        )}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        sx={{ minHeight: 42.4, borderColor: "red" }}
                      >
                        {GENERAL_CONSTS.COUNTRIES.map((item, idx) => (
                          <MenuItem
                            sx={{ my: 1, borderRadius: 50, width: "min-content", color: "info" }}
                            key={idx}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid> */}

                {/* <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={2} px={0.5}>
                    <FormControl
                      fullWidth
                      error={errors.importCountries && touched.importCountries && true}
                      focused={errors.importCountries && touched.importCountries && true}
                    >
                      <InputLabel id="demo-multiple-chip-label">
                        {errors.importCountries && touched.importCountries
                          ? errors.importCountries
                          : "Top Countries Importing From"}
                      </InputLabel>
                      <Select
                        error={errors.importCountries && touched.importCountries && true}
                        label={
                          errors.importCountries && touched.importCountries
                            ? errors.importCountries
                            : "Top Countries Importing From"
                        }
                        id="label"
                        labelId="demo-multiple-name-label"
                        multiple
                        fullWidth
                        {...getFieldProps("importCountries")}
                        value={values.importCountries}
                        renderValue={(selected) => (
                          <MDBox
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignContent: "center",
                              overflowX: "auto",
                              minHeight: 42.4,
                              gap: 1,
                              color: "white",
                            }}
                            py={1}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  <MDTypography variant="button" color="black">
                                    {value}
                                  </MDTypography>
                                }
                              />
                            ))}
                          </MDBox>
                        )}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        sx={{ minHeight: 42.4, borderColor: "red" }}
                      >
                        {GENERAL_CONSTS.COUNTRIES.map((item, idx) => (
                          <MenuItem
                            sx={{ my: 1, borderRadius: 50, width: "min-content", color: "info" }}
                            key={idx}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid> */}

                {/* <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={2} px={0.5}>
                    <FormControl
                      fullWidth
                      error={errors.carriers && touched.carriers && true}
                      focused={errors.carriers && touched.carriers && true}
                    >
                      <InputLabel id="demo-multiple-chip-label">
                        {errors.carriers && touched.carriers
                          ? errors.carriers
                          : "Top Carriers Used"}
                      </InputLabel>
                      <Select
                        error={errors.carriers && touched.carriers && true}
                        label={
                          errors.carriers && touched.carriers
                            ? errors.carriers
                            : "Top Carriers Used"
                        }
                        id="label"
                        labelId="demo-multiple-name-label"
                        multiple
                        fullWidth
                        {...getFieldProps("carriers")}
                        value={values.carriers}
                        renderValue={(selected) => (
                          <MDBox
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignContent: "center",
                              overflowX: "auto",
                              minHeight: 42.4,
                              gap: 1,
                              color: "white",
                            }}
                            py={1}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value.id}
                                label={
                                  <MDTypography variant="button" color="black">
                                    {value.name}
                                  </MDTypography>
                                }
                              />
                            ))}
                          </MDBox>
                        )}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        sx={{ minHeight: 42.4, borderColor: "red" }}
                      >
                        {carrierList.map((item, idx) => (
                          <MenuItem
                            sx={{ my: 1, borderRadius: 50, width: "min-content", color: "info" }}
                            key={idx}
                            value={item}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid> */}

                {/* <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={2} px={0.5}>
                    <FormControl
                      fullWidth
                      error={errors.airlines && touched.airlines && true}
                      focused={errors.airlines && touched.airlines && true}
                    >
                      <InputLabel id="demo-multiple-chip-label">
                        {errors.airlines && touched.airlines
                          ? errors.airlines
                          : "Top Airlines Used"}
                      </InputLabel>
                      <Select
                        error={errors.airlines && touched.airlines && true}
                        label={
                          errors.airlines && touched.airlines
                            ? errors.airlines
                            : "Top Airlines Used"
                        }
                        id="label"
                        labelId="demo-multiple-name-label"
                        multiple
                        fullWidth
                        {...getFieldProps("airlines")}
                        value={values.airlines}
                        renderValue={(selected) => (
                          <MDBox
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignContent: "center",
                              overflowX: "auto",
                              minHeight: 42.4,
                              gap: 1,
                              color: "white",
                            }}
                            py={1}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value.id}
                                label={
                                  <MDTypography variant="button" color="black">
                                    {value.name}
                                  </MDTypography>
                                }
                              />
                            ))}
                          </MDBox>
                        )}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        sx={{ minHeight: 42.4, borderColor: "red" }}
                      >
                        {airlineList.map((item, idx) => (
                          <MenuItem
                            sx={{ my: 1, borderRadius: 50, width: "min-content", color: "info" }}
                            key={idx}
                            value={item}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid> */}

                <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={1}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" my={3} ml={1}>
                      Company Profile
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ borderRadius: "5px" }} color="text" px={0.5}>
                    <ReactQuill
                      color="text"
                      theme="snow"
                      onChange={handleEditorChange}
                      value={profileText}
                      id="companyProfile"
                    />
                  </MDBox>
                  {profileValidateMsg && (
                    <MDTypography fontSize={12} ml={1} mt={1} variant="button" color="error">
                      {profileValidateMsg}
                    </MDTypography>
                  )}
                </Grid>

                {/* <Grid xs={12} sm={12} md={6} xl={6}>
                    <MDBox mt={3} px={0.5}>
                      <MDInput
                        type="text"
                        error={errors.fulladdress && touched.fulladdress && true}
                        focused={
                          (errors.fulladdress && touched.fulladdress && true) ||
                          (values.fulladdress && true)
                        }
                        label={
                          errors.fulladdress && touched.fulladdress
                            ? errors.fulladdress
                            : "Full Address"
                        }
                        id="fulladdress"
                        onChange={handleChange}
                        value={values.fulladdress}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid xs={12} md={6} xl={6} mt={3}>
                    <MDBox display="flex" alignItems="center" px={0.5}>
                      <FormControl
                        container
                        fullWidth
                        error={errors.country && touched.country && true}
                        focused={
                          (errors.country && touched.country && true) || (values.country && true)
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          {errors.country && touched.country ? errors.country : "Country"}
                        </InputLabel>
                        <Select
                          error={errors.country && touched.country && true}
                          focused={
                            (errors.country && touched.country && true) || (values.country && true)
                          }
                          label={errors.country && touched.country ? errors.country : "Country"}
                          id="country"
                          fullWidth
                          {...getFieldProps("country")}
                          value={values.country}
                          labelId="demo-simple-select-label"
                          onChange={handleChange}
                          sx={{ height: 36 }}
                        >
                          <MenuItem value="Sri Lanka">Sri Lanka</MenuItem>
                          <MenuItem value="Canada">Canada</MenuItem>
                          <MenuItem value="UK">UK</MenuItem>
                        </Select>
                      </FormControl>
                    </MDBox>
                  </Grid>
                  <Grid xs={12} sm={12} md={6} xl={6}>
                    <MDBox mt={3} px={0.5}>
                      <MDInput
                        type="text"
                        error={errors.state && touched.state && true}
                        focused={(errors.state && touched.state && true) || (values.state && true)}
                        label={errors.state && touched.state ? errors.state : "State"}
                        id="state"
                        onChange={handleChange}
                        value={values.state}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid xs={12} sm={12} md={6} xl={6}>
                    <MDBox mt={3} px={0.5}>
                      <MDInput
                        type="text"
                        fullWidth
                        error={errors.city && touched.city && true}
                        focused={(errors.city && touched.city && true) || (values.city && true)}
                        label={errors.city && touched.city ? errors.city : "City"}
                        id="city"
                        onChange={handleChange}
                        value={values.city}
                        onBlur={handleBlur}
                      />
                    </MDBox>
                  </Grid> */}

                {/* {memberAddress.map((data, index) => (
                    <>
                      <Grid xs={12} md={12} xl={12} key={index}>
                        <MDBox mt={2}>
                          <MDTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            ml={1.5}
                            textTransform="capitalize"
                          >
                            Address {" 0" + (index + 1)}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <AddressComponent address={data} />
                    </>
                  ))} */}

                <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={3}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" my={2} ml={1}>
                      Address
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={1} px={0.5}>
                    <MDInput
                      type="text"
                      error={errors.unitNo && touched.unitNo && true}
                      focused={(errors.unitNo && touched.unitNo && true) || (values.unitNo && true)}
                      label={errors.unitNo && touched.unitNo ? errors.unitNo : "Street"}
                      id="unitNo"
                      onChange={handleChange}
                      value={values.unitNo}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                {/* <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="text"
                      error={errors.street && touched.street && true}
                      focused={(errors.street && touched.street && true) || (values.street && true)}
                      label={errors.street && touched.street ? errors.street : "Street"}
                      id="street"
                      onChange={handleChange}
                      value={values.street}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid> */}

                <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={1} px={0.5}>
                    <MDInput
                      type="text"
                      fullWidth
                      error={errors.city && touched.city && true}
                      focused={(errors.city && touched.city && true) || (values.city && true)}
                      label={errors.city && touched.city ? errors.city : "City"}
                      id="city"
                      onChange={handleChange}
                      value={values.city}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="text"
                      error={errors.postcode && touched.postcode && true}
                      focused={
                        (errors.postcode && touched.postcode && true) || (values.postcode && true)
                      }
                      label={errors.postcode && touched.postcode ? errors.postcode : "Post Code"}
                      id="postcode"
                      onChange={handleChange}
                      value={values.postcode}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} md={6} xl={6} mt={2}>
                  <MDBox display="flex" alignItems="center" px={0.5}>
                    <FormControl
                      container
                      fullWidth
                      error={errors.country && touched.country && true}
                      focused={
                        (errors.country && touched.country && true) || (values.country && true)
                      }
                    >
                      <InputLabel id="demo-simple-select-label">
                        {errors.country && touched.country ? errors.country : "Country"}
                      </InputLabel>
                      <Select
                        error={errors.country && touched.country && true}
                        focused={
                          (errors.country && touched.country && true) || (values.country && true)
                        }
                        label={errors.country && touched.country ? errors.country : "Country"}
                        id="country"
                        fullWidth
                        {...getFieldProps("country")}
                        value={values.country}
                        labelId="demo-simple-select-label"
                        onChange={handleChange}
                        sx={{ height: 35.2 }}
                      >
                        {GENERAL_CONSTS.COUNTRIES.map((country, idx) => (
                          <MenuItem key={idx} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                        {/* <MenuItem value="Sri Lanka">Sri Lanka</MenuItem>
                          <MenuItem value="Canada">Canada</MenuItem>
                          <MenuItem value="UK">UK</MenuItem> */}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid>

                {/* <Grid xs={12} sm={12} md={6} xl={6}>
                    <MDBox mt={2} px={0.5}>
                      <MDInput
                        type="text"
                        error={errors.province && touched.province && true}
                        focused={
                          (errors.province && touched.province && true) || (values.province && true)
                        }
                        label={errors.province && touched.province ? errors.province : "State"}
                        id="province"
                        onChange={handleChange}
                        value={values.province}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </MDBox>
                  </Grid> */}

                <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={4}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" my={2} ml={1}>
                      Location
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0}>
                    <MDTypography
                      variant="button"
                      color="textButton"
                      fontWeight="bold"
                      ml={1}
                      textTransform="capitalize"
                      component="a"
                      href="https://www.latlong.net/convert-address-to-lat-long.html"
                      target="_blank"
                    >
                      Get Lat Long from Address
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="text"
                      error={errors.lat && touched.lat && true}
                      focused={(errors.lat && touched.lat && true) || (values.lat && true)}
                      label={errors.lat && touched.lat ? errors.lat : "Latitude"}
                      id="lat"
                      onChange={handleChange}
                      value={values.lat}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="text"
                      error={errors.long && touched.long && true}
                      focused={(errors.long && touched.long && true) || (values.long && true)}
                      label={errors.long && touched.long ? errors.long : "Longitude"}
                      id="long"
                      onChange={handleChange}
                      value={values.long}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={3}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" my={2} ml={1}>
                      Contact
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid xs={12} md={6} xl={6}>
                  <MDBox mt={1} px={0.5}>
                    <MDInput
                      type="text"
                      fullWidth
                      error={errors.websiteUrl && touched.websiteUrl && true}
                      focused={
                        (errors.websiteUrl && touched.websiteUrl && true) ||
                        (values.websiteUrl && true)
                      }
                      label={
                        errors.websiteUrl && touched.websiteUrl ? errors.websiteUrl : "Website"
                      }
                      id="websiteUrl"
                      onChange={handleChange}
                      value={values.websiteUrl}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} md={6} xl={6}>
                  <MDBox mt={1} px={0.5}>
                    <MDInput
                      type="text"
                      fullWidth
                      error={errors.linkedInUrl && touched.linkedInUrl && true}
                      focused={
                        (errors.linkedInUrl && touched.linkedInUrl && true) ||
                        (values.linkedInUrl && true)
                      }
                      label={
                        errors.linkedInUrl && touched.linkedInUrl
                          ? errors.linkedInUrl
                          : "LinkedIn URL"
                      }
                      id="linkedInUrl"
                      onChange={handleChange}
                      value={values.linkedInUrl}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                {/* <Grid xs={12} md={6} xl={6}>
                    <MDBox mt={3} px={0.5}>
                      <MDInput
                        type="email"
                        fullWidth
                        error={errors.email && touched.email && true}
                        focused={(errors.email && touched.email && true) || (values.email && true)}
                        label={errors.email && touched.email ? errors.email : "Company Email"}
                        id="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                      />
                    </MDBox>
                  </Grid> */}

                <Grid xs={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="text"
                      fullWidth
                      error={errors.officeContactNumber && touched.officeContactNumber && true}
                      focused={
                        (errors.officeContactNumber && touched.officeContactNumber && true) ||
                        (values.officeContactNumber && true)
                      }
                      label={
                        errors.officeContactNumber && touched.officeContactNumber
                          ? errors.officeContactNumber
                          : "Contact Number"
                      }
                      id="officeContactNumber"
                      onChange={handleChange}
                      value={values.officeContactNumber}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      error={errors.email && touched.email && true}
                      focused={errors.email && touched.email && true}
                      label={errors.email && touched.email ? errors.email : "Company Email"}
                      id="email"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      type="email"
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} mt={3} md={6} xl={6}>
                  <MDBox px={0.5}>
                    <MDInput
                      type={"date"}
                      fullWidth
                      error={errors.approvedTimestamp && touched.approvedTimestamp && true}
                      focused={
                        (errors.approvedTimestamp && touched.approvedTimestamp && true) ||
                        (values.approvedTimestamp && true)
                      }
                      label={
                        errors.approvedTimestamp && touched.approvedTimestamp
                          ? errors.approvedTimestamp
                          : "Enrollment Date"
                      }
                      id="approvedTimestamp"
                      onChange={handleChange}
                      value={values.approvedTimestamp}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} mt={3} md={6} xl={6}>
                  <MDBox px={0.5}>
                    <MDInput
                      error={errors.expiryDate && touched.expiryDate && true}
                      focused={
                        (errors.expiryDate && touched.expiryDate && true) ||
                        (values.expiryDate && true)
                      }
                      label={
                        errors.expiryDate && touched.expiryDate ? errors.expiryDate : "Expiry Date"
                      }
                      id="expiryDate"
                      onChange={handleChange}
                      value={values.expiryDate}
                      onBlur={handleBlur}
                      type={"date"}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12} mt={1}>
                  <MDBox px={0.5}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" mt={3} ml={1}>
                      Company Logo
                    </MDTypography>
                    {errors.file && touched.file && (
                      <MDBox pl={1} mb={1}>
                        <MDTypography variant="button" color="error" sx={{ fontSize: 9.6 }}>
                          {errors.file}
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12}>
                  <MDBox px={0.5} mt={3}>
                    <MDBox
                      {...getRootLogoProps({ className: "dropzone" })}
                      color={errors.file && touched.file ? "error" : "text"}
                      variant="outlined"
                      height="100%"
                      sx={{
                        border: errors.file && touched.file ? "1.6px solid" : "1.6px dotted",
                        borderColor: "text",
                        borderRadius: "5px",
                      }}
                      py={3}
                      px={8}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <MDBox>
                        <input {...getInputLogoProps()} />
                        <MDTypography color="text" sx={{ cursor: "pointer" }} variant="button">
                          Drop files here, or Click to select files
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDBox mt={2} display="flex" alignItems="center" justifyContent="center">
                      {errors.file && touched.file ? "" : fileAcceptedItems}
                    </MDBox>
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12} mt={3}>
                  <MDBox mx={1} mb={1}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" mb={2}>
                      Conferences
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12}>
                  <MDBox mx={1} mb={3}>
                    <ThemeProvider theme={theme}>
                      <Grid container>
                        {GENERAL_CONSTS.CONFERENCES.map((conference) => (
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="conferences"
                                  value={conference.name}
                                  onChange={handleCheckChange}
                                  onBlur={handleBlur}
                                  checked={
                                    values.conferences
                                      ? values.conferences.includes(conference.name)
                                      : false
                                  }
                                />
                              }
                              label={
                                <MDTypography
                                  variant="button"
                                  fontWeight="regular"
                                  color="black"
                                  width="max-content"
                                >
                                  <span style={{ color: "black", fontSize: "11.2px" }}>
                                    {conference.name}
                                  </span>
                                </MDTypography>
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </ThemeProvider>
                    {errors.conferences && touched.conferences ? (
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        mb={1}
                        fontSize={12}
                        color="error"
                      >
                        {errors.conferences}
                      </MDTypography>
                    ) : null}
                  </MDBox>
                </Grid>

                <Grid
                  xs={12}
                  md={12}
                  xl={12}
                  pt={4}
                  px={1}
                  mb={2}
                  display="flex"
                  justifyContent="end"
                >
                  {editor ? (
                    <MDButton
                      size="medium"
                      variant="gradient"
                      color="primary"
                      sx={{ px: 8 }}
                      type="submit"
                    >
                      Add Company
                    </MDButton>
                  ) : null}
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
        <Footer />
      </DashboardLayout>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          Member creation failed!
        </Alert>
      </Snackbar>

      {/* Dialog Popup //////////////////////////////////////////// */}

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={loadingOpen}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={2} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Saving Details ...
            </MDTypography>
            {saving && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
        </Dialog>
      </MDBox>

      {/* //////////////////////////////////////////// */}
    </>
  );
}

export default AddBranch;
