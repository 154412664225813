import { useState, useEffect, useMemo, lazy } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "common/Sidenav";
import Configurator from "common/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ROLE_ACCESS } from "utils/constants/roles.constants";

import { useTranslation } from "react-i18next";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhiteBack from "assets/images/logo-ct-darkback.png";
import brandDarkBack from "assets/images/logo-ct-darkback.png";
import { UserAuth } from "context/AuthContext";
// Material Dashboard 2 React routes
import Dashboard from "layouts/dashboard";
import AddBranch from "layouts/profile/users/member/addBranch";
import EditOption from "layouts/member-options/editOption";
import Banners from "layouts/banners/banners";
import AddBanner from "layouts/banners/addBanner";
import EditBanner from "layouts/banners/editBanner";
import FavouriteMembers from "layouts/profile/users/member/favouriteMembers";
import PotentialPartners from "layouts/profile/potentialPartners/potentialPartners";
import UpdateRequests from "layouts/profile/updateRequests/updateRequests";
import UserProfileEditRequest from "layouts/profile/users/contact/userProfileEditRequest";
import FreytInsure from "layouts/freyt-insure";
const SignIn = lazy(() => import("layouts/authentication/sign-in/signIn"));
const SignUp = lazy(() => import("layouts/authentication/sign-up"));

const AddUser = lazy(() => import("layouts/profile/users/contact/addUser"));
const Users = lazy(() => import("layouts/profile/users/contact/users"));
const AddMember = lazy(() => import("layouts/profile/users/member/addMember"));
const UserProfile = lazy(() => import("layouts/profile/users/contact/userProfile"));
const MemberProfile = lazy(() => import("layouts/profile/users/member/memberProfile"));
const MemberProfileSearch = lazy(() => import("layouts/profile/users/member/memberProfileSearch"));
const Downloads = lazy(() => import("layouts/download/downloads"));
const Announcements = lazy(() => import("layouts/announcements/announcements"));
const LoginRecords = lazy(() => import("layouts/audit/data/loginRecords"));
const ExpiringMembers = lazy(() => import("layouts/audit/data/expiringMembers"));
const ExpiredMembers = lazy(() => import("layouts/audit/data/expiredMembers"));
const DepartedCompanies = lazy(() => import("layouts/audit/data/departedCompanies"));
const ProtectedRoute = lazy(() => import("ProtectedRoute"));
const EditUser = lazy(() => import("layouts/profile/users/contact/editUser"));
const OfficeCount = lazy(() => import("layouts/authentication/applyOnline/officeCount"));
const HeadOffice = lazy(() => import("layouts/authentication/applyOnline/headOffice"));
const EditMember = lazy(() => import("layouts/profile/users/member/editMember"));

const BranchOffice = lazy(() => import("layouts/authentication/applyOnline/branchOffice"));
const HeadOfficeGuaranty = lazy(
  () => import("layouts/authentication/applyOnline/headOfficeGuaranty")
);
const BusinessAndServices = lazy(
  () => import("layouts/authentication/applyOnline/businessAndServices")
);
const AgentReferences = lazy(() => import("layouts/authentication/applyOnline/agentReferences"));
const NewAnnouncement = lazy(() => import("layouts/announcements/createAnnouncement"));
const EditAnnouncement = lazy(() => import("layouts/announcements/editAnnouncements"));
const UploadFile = lazy(() => import("layouts/download/uploadFile"));
const AddAdditionalUser = lazy(
  () => import("layouts/authentication/applyOnline/addAdditionalUser")
);
const PendingRequests = lazy(() => import("layouts/profile/pendingRequests/pendingRequests"));
const Resetpassword = lazy(() => import("layouts/authentication/reset-password/resetPassword"));
const Resetpasswordpage = lazy(
  () => import("layouts/authentication/reset-password/resetPasswordPage")
);
const UpdatePassword = lazy(() => import("layouts/authentication/reset-password/updatePassword"));
const ConferenceRegistrations = lazy(() => import("layouts/conference/conferenceRegistrations"));
const EditConferenceReg = lazy(() => import("layouts/conference/editConferenceReg"));
const AddAttendee = lazy(() => import("layouts/conference/addAttendee"));
const Members = lazy(() => import("layouts/profile/users/member/members"));
const SearchAttendees = lazy(() => import("layouts/conference/searchAttendees"));

export default function App() {
  const { t } = useTranslation();
  const routes = [
    {
      type: "collapse",
      name: t("sidebar.dashboard"),
      key: "dashboard",
      tabType: "dashboardTab",
      icon: (
        <Icon fontSize="small" color="secondary">
          dashboard
        </Icon>
      ),
      route: "/dashboard",
      component: (
        <ProtectedRoute tabType="dashboardTab">
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      type: "collapse",
      name: "Directory",
      key: "directory",
      icon: (
        <Icon fontSize="small" color="secondary">
          apartment_icon
        </Icon>
      ),
      route: "/directory",
      tabType: "companiesTab",
      component: (
        <ProtectedRoute tabType="companiesTab">
          <Members />
        </ProtectedRoute>
      ),
    },
    {
      type: "collapse",
      name: t("sidebar.announcements"),
      key: "announcements",
      tabType: "announcementsTab",
      icon: (
        <Icon fontSize="medium" color="secondary">
          campaign_icon
        </Icon>
      ),
      route: "/announcements",
      component: (
        <ProtectedRoute tabType="announcementsTab">
          {" "}
          <Announcements />
        </ProtectedRoute>
      ),
    },

    {
      type: "collapse",
      name: t("sidebar.potentialPartners"),
      key: "potential_partners",
      icon: (
        <Icon fontSize="medium" color="secondary">
          handshake
        </Icon>
      ),
      route: "/potential_partners",
      tabType: "companiesTab",
      component: (
        <ProtectedRoute tabType="companiesTab">
          <PotentialPartners />
        </ProtectedRoute>
      ),
    },

    {
      type: "collapse",
      name: "Favourites",
      key: "favourites",
      icon: (
        <Icon fontSize="small" color="secondary">
          star_icon
        </Icon>
      ),
      route: "/favourites",
      tabType: ROLE_ACCESS.FAVOURITES_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.FAVOURITES_ACCESS}>
          <FavouriteMembers />
        </ProtectedRoute>
      ),
    },
    {
      type: "collapse",
      name: t("sidebar.contacts"),
      key: "contacts",
      icon: (
        <Icon fontSize="small" color="secondary">
          contacts_icon
        </Icon>
      ),
      route: "/contacts",
      tabType: "contactsTab",
      component: (
        <ProtectedRoute tabType="contactsTab">
          <Users />
        </ProtectedRoute>
      ),
    },
    // {
    //   type: "collapse",
    //   name: "Freyt Insure",
    //   key: "freyt-insure",
    //   icon: (
    //     <Icon fontSize="small" color="secondary">
    //       shield_icon
    //     </Icon>
    //   ),
    //   route: "/freyt-insure",
    //   tabType: ROLE_ACCESS.FAVOURITES_ACCESS,
    //   component: (
    //     <ProtectedRoute tabType={ROLE_ACCESS.FAVOURITES_ACCESS}>
    //       <FreytInsure />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      type: "collapse",
      name: t("sidebar.downloads"),
      key: "downloads",
      icon: (
        <Icon fontSize="medium" color="secondary">
          download
        </Icon>
      ),
      route: "/downloads",
      tabType: "downloadsTab",
      component: (
        <ProtectedRoute tabType="downloadsTab">
          <Downloads />
        </ProtectedRoute>
      ),
    },
    {
      type: "collapse",
      name: "Expiring Companies",
      key: "expiring_companies",
      icon: (
        <Icon fontSize="small" color="secondary">
          timelapse_icon
        </Icon>
      ),
      route: "/expiring_companies",
      tabType: "companiesTab",
      component: (
        <ProtectedRoute tabType="companiesTab">
          <ExpiringMembers />
        </ProtectedRoute>
      ),
    },

    {
      type: "collapse",
      name: "Expired Companies",
      key: "expired_companies",
      icon: (
        <Icon fontSize="small" color="secondary">
          running_with_errors_icon
        </Icon>
      ),
      route: "/expired_companies",
      tabType: "companiesTab",
      component: (
        <ProtectedRoute tabType="companiesTab">
          <ExpiredMembers />
        </ProtectedRoute>
      ),
    },
    {
      type: "collapse",
      name: "Departed Companies",
      key: "departed_companies",
      icon: (
        <Icon fontSize="small" color="secondary">
          meeting_room_icon
        </Icon>
      ),
      route: "/departed_companies",
      tabType: "companiesTab",
      component: (
        <ProtectedRoute tabType="companiesTab">
          <DepartedCompanies />
        </ProtectedRoute>
      ),
    },
    {
      type: "submenu",
      name: "Admin Operations",
      key: "admin_operations",
      icon: (
        <Icon fontSize="small" color="secondary">
          settings_applications_icon
        </Icon>
      ),
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      nestedItems: [
        {
          type: "collapse",
          name: "Banners",
          key: "banners",
          icon: (
            <Icon fontSize="small" color="secondary">
              burst_mode_icon
            </Icon>
          ),
          route: "/banners",
        },
        {
          type: "collapse",
          name: "Update Requests",
          key: "update_requests",
          icon: (
            <Icon fontSize="small" color="secondary">
              pending_actions_icon
            </Icon>
          ),
          route: "/update_requests",
        },
        // {
        //   type: "collapse",
        //   name: "Pending Requests",
        //   key: "pending_requests",
        //   icon: (
        //     <Icon fontSize="small" color="secondary">
        //       pending_actions_icon
        //     </Icon>
        //   ),
        //   route: "/pending_requests",
        // },
        // {
        //   type: "collapse",
        //   name: "Conference Registrations",
        //   key: "conference_registrations",
        //   icon: (
        //     <Icon fontSize="small" color="secondary">
        //       groups_icon
        //     </Icon>
        //   ),
        //   route: "/conference_registrations",
        // },
        // {
        //   type: "collapse",
        //   name: "Services",
        //   key: "services",
        //   icon: (
        //     <Icon fontSize="small" color="secondary">
        //       home_repair_service_icon
        //     </Icon>
        //   ),
        //   route: "/services",
        // },
        // {
        //   type: "collapse",
        //   name: "Airlines",
        //   key: "airlines",
        //   icon: (
        //     <Icon fontSize="small" color="secondary">
        //       airlines_icon
        //     </Icon>
        //   ),
        //   route: "/airlines",
        // },
        // {
        //   type: "collapse",
        //   name: "Carriers",
        //   key: "carriers",
        //   icon: (
        //     <Icon fontSize="small" color="secondary">
        //       directions_bus_icon
        //     </Icon>
        //   ),
        //   route: "/carriers",
        // },
        {
          type: "collapse",
          name: "Login Records",
          key: "login_records",
          icon: (
            <Icon fontSize="small" color="secondary">
              login_icon
            </Icon>
          ),
          route: "/login_records",
        },
      ],
    },
    {
      key: "profile",
      route: "/contacts/profile/:id",
      tabType: "open",
      component: (
        <ProtectedRoute tabType="open">
          <UserProfile />
        </ProtectedRoute>
      ),
    },
    {
      key: "update-password",
      route: "/change-password",
      tabType: "open",
      component: (
        <ProtectedRoute tabType="open">
          <UpdatePassword />
        </ProtectedRoute>
      ),
    },
    {
      key: "memberprofile",
      route: "/directory/profile/:id",
      tabType: "companiesTab",
      component: (
        <ProtectedRoute tabType="companiesTab">
          <MemberProfile />
        </ProtectedRoute>
      ),
    },
    {
      key: "memberprofilesearch",
      route: "/directory/search/:id",
      tabType: "companiesTab",
      component: (
        <ProtectedRoute tabType="companiesTab">
          <MemberProfileSearch />
        </ProtectedRoute>
      ),
    },
    {
      key: "sign-in",
      route: "/authentication/sign-in",
      component: <SignIn />,
    },
    {
      key: "reset-password",
      route: "/authentication/reset-password",
      component: <Resetpasswordpage />,
    },
    {
      key: "sign-up",
      route: "/authentication/sign-up",
      component: <SignUp />,
    },
    {
      key: "reset-password-email",
      route: "/authentication/reset-password-email",
      component: <Resetpassword />,
    },
    {
      key: "offices",
      route: "/new-member/offices",
      component: <OfficeCount />,
    },
    {
      key: "head-office",
      route: "/new-member/head-office",
      component: <HeadOffice />,
    },
    {
      key: "head-office",
      route: "/new-member/head-office/add-user",
      component: <AddAdditionalUser />,
    },
    {
      key: "branch-office",
      route: "/new-member/branch-office",
      component: <BranchOffice />,
    },

    {
      key: "head-office-guaranty",
      route: "/new-member/head-office-guaranty",
      component: <HeadOfficeGuaranty />,
    },
    {
      key: "business-and-services",
      route: "/new-member/business-and-services",
      component: <BusinessAndServices />,
    },
    {
      key: "agent-references",
      route: "/new-member/agent-references",
      component: <AgentReferences />,
    },
    {
      key: "add-user",
      route: "/add-contact",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <AddUser />
        </ProtectedRoute>
      ),
    },
    {
      key: "banners",
      route: "/banners",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <Banners />
        </ProtectedRoute>
      ),
    },
    {
      key: "update_requests",
      route: "/update_requests",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <UpdateRequests />
        </ProtectedRoute>
      ),
    },
    {
      key: "pending_requests",
      route: "/pending_requests",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <PendingRequests />
        </ProtectedRoute>
      ),
    },
    {
      key: "conference_registrations",
      route: "/conference_registrations",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <ConferenceRegistrations />
        </ProtectedRoute>
      ),
    },
    // {
    //   key: "services",
    //   route: "/services",
    //   tabType: ROLE_ACCESS.AUDIT_ACCESS,
    //   component: (
    //     <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
    //       <ServiceOptions />
    //     </ProtectedRoute>
    //   ),
    // },
    // {
    //   key: "airlines",
    //   route: "/airlines",
    //   tabType: ROLE_ACCESS.AUDIT_ACCESS,
    //   component: (
    //     <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
    //       <AirlineOptions />
    //     </ProtectedRoute>
    //   ),
    // },
    // {
    //   key: "carriers",
    //   route: "/carriers",
    //   tabType: ROLE_ACCESS.AUDIT_ACCESS,
    //   component: (
    //     <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
    //       <CarrierOptions />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      key: "login_records",
      route: "/login_records",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <LoginRecords />
        </ProtectedRoute>
      ),
    },
    {
      key: "edit-user",
      route: "/update-contact/:id",
      tabType: "open",
      component: (
        <ProtectedRoute tabType="open">
          <EditUser />
        </ProtectedRoute>
      ),
    },
    {
      key: "edit-user",
      route: "/update_requests/:id",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <UserProfileEditRequest />
        </ProtectedRoute>
      ),
    },
    {
      key: "add-attendees",
      route: "/add-attendees",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <SearchAttendees />
        </ProtectedRoute>
      ),
    },
    {
      key: "add-attendees-new",
      route: "/add-attendees/:id",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <AddAttendee />
        </ProtectedRoute>
      ),
    },
    {
      key: "edit-reg",
      route: "/update-conference-registration/:id",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <EditConferenceReg />
        </ProtectedRoute>
      ),
    },
    {
      key: "update-banner",
      route: "/update-banner/:id",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <EditBanner />
        </ProtectedRoute>
      ),
    },
    {
      key: "edit-member",
      route: "/update-member/:id",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <EditMember />
        </ProtectedRoute>
      ),
    },
    {
      key: "add-member",
      route: "/add-member",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <AddMember />
        </ProtectedRoute>
      ),
    },
    {
      key: "add-banner",
      route: "/add-banner",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <AddBanner />
        </ProtectedRoute>
      ),
    },
    {
      key: "add-branch",
      route: "/add-branch/:id",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <AddBranch />
        </ProtectedRoute>
      ),
    },
    {
      key: "make-announcement",
      route: "/make-announcement",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <NewAnnouncement />
        </ProtectedRoute>
      ),
    },
    {
      key: "update-announcement",
      route: "/update-announcement/:id",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <EditAnnouncement />
        </ProtectedRoute>
      ),
    },
    {
      key: "upload-document",
      route: "/upload-document",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <UploadFile />
        </ProtectedRoute>
      ),
    },
    {
      key: "update-option",
      route: "/update-option/:id",
      tabType: ROLE_ACCESS.AUDIT_ACCESS,
      component: (
        <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
          <EditOption />
        </ProtectedRoute>
      ),
    },
    // {
    //   key: "add-service",
    //   route: "/add-service",
    //   tabType: ROLE_ACCESS.AUDIT_ACCESS,
    //   component: (
    //     <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
    //       <AddService />
    //     </ProtectedRoute>
    //   ),
    // },
    // {
    //   key: "add-carrier",
    //   route: "/add-carrier",
    //   tabType: ROLE_ACCESS.AUDIT_ACCESS,
    //   component: (
    //     <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
    //       <AddCarrier />
    //     </ProtectedRoute>
    //   ),
    // },
    // {
    //   key: "add-airline",
    //   route: "/add-airline",
    //   tabType: ROLE_ACCESS.AUDIT_ACCESS,
    //   component: (
    //     <ProtectedRoute tabType={ROLE_ACCESS.AUDIT_ACCESS}>
    //       <AddAirline />
    //     </ProtectedRoute>
    //   ),
    // },
  ];

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3rem"
      height="3rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={goToTop}
    >
      <Icon fontSize="large" color="primaryDark">
        <KeyboardArrowUpIcon />
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav ? brandWhiteBack : brandDarkBack
              }
              brandName="Material Dashboard 2"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav ? brandWhiteBack : brandDarkBack
            }
            brandName="FREYT CONSOL"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
